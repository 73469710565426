@import "core";
.section.home-brands {
	padding: 5rem 0 0;
	background-color: $color-bg;
	text-align: center;

	.brands-title {
		font-size: 5rem;
		margin-bottom: 3rem;

		@include media {
			margin-bottom: 2rem;
			font-size: 3.6rem;
		}
	}

	.brands-content {
		font-size: 1.8rem;
		line-height: 1.4em;

		@include media {
			margin-top: 3rem;
			font-size: 1.4rem;
		}
	}

	.brands-list {
		margin-top: 5rem;
		background-color: $color-white;
		padding: 5rem 0;
	}

	.list-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% + 4rem);
		margin: 0 -2rem;

		@include media {
			width: calc(100% + 2rem);
			margin: 0 -1rem;
		}
	}

	.list-brand {
		padding: 0 2rem;

		@include media {
			padding: 0 1rem;
		}
	}

	.brand-logo {
		height: 6rem;
	}
}
