@import "core";
.section.home-services {
  .services-heading {
    padding: 5rem 0;
    background-color: $color-bg;
    text-align: center;
  }

    .heading-title {
      font-size: 5rem;
      margin-bottom: 3rem;

      @include media {
        font-size: 3.6rem;
      }
    }

    .heading-content {
      font-size: 2.4rem;
      margin-top: 2rem;
      line-height: 1.4em;

      @include media {
        font-size: 1.8rem;
      }
    }

  .services-section {
    padding: 5rem 0;

    &:nth-child(2n + 1) {
      background-color: $color-bg;

      .section-points li {
        background-color: $color-white;
      }
    }
  }

    .section-title {
      font-size: 3rem;
      margin-bottom: 3rem;

      @include media {
        font-size: 2.4rem;
        margin-bottom: 2rem;
        text-align: center;
      }
    }

    .section-points {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: calc(100% + 1rem);
      margin-right: -1rem;
      margin-bottom: -.6rem;

      @include media {
        justify-content: center;
      }

      li {
        background-color: $color-bg;
        padding: 1rem;
        margin-right: 1rem;
        margin-bottom: .6rem;
      }
    }
}