@import "core";
.section.home-contact {
  padding: 4rem 0;
  background-color: $color-bg;

  .contact-wrap {
    background-color: $color-white;
    padding: 4rem;

    @include media {
      padding: 3rem 2rem;
    }
  }

  .contact-title {
    text-align: center;
    font-size: 5rem;
    margin-bottom: 3rem;

    @include media {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }

  .contact-success {
    text-align: center;
  }

    .success-message {
      margin-bottom: 2rem;
      font-size: 2rem;
    }
}