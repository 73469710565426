@import "core";
@keyframes anim_scrollbtn {
  30%, 80% {
    opacity: 0;
  }
	0% {
		transform: translate3d(-50%, -30%, 0);
	}
  50% {
    opacity: 1;
  }
  100% {
    transform: translate3d(-50%, 30%, 0);
  }
}

.page-home {
	.home-intro {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
		background-color: $color-black;
		color: $color-white;

		.intro-content {
			text-align: left;
			padding: 2rem;
			max-width: 36rem;
			width: 100%;
			font-weight: inherit;
		}

			.content-pretitle {
				@include font-bold;
				display: block;
				margin-bottom: 1rem;
				font-size: 1.8rem;
			}

			.content-logo {
				width: 100%;
			}

			.content-description {
				font-size: 1.6rem;
				margin-top: 2rem;
				line-height: 1.2em;
				display: block;
			}

		.intro-scrollbtn {
			bottom: 1rem;
			left: 50%;
			position: absolute;
			font-size: 4rem;
			transform: translate3d(-50%, -100%, 0);
			opacity: 0;
			animation: anim_scrollbtn 2500ms ease 1000ms infinite;

			@include media {
				font-size: 3.6rem;
			}
		}
	}
}